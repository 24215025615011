<template>
  <div class="p-4">
    <div class="register-box">

      <div class="logo">
        <img src="../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="loading-images">
          <img class="loading1" src="../assets/img/loading1.png" alt="">
          <img class="loading2" src="../assets/img/loading1.png" alt="">
          <img class="loading3" src="../assets/img/loading1.png" alt="">
        </div>



    <div class="bottom">
      <h4>داشبورد شما در حال آماده سازیست...</h4>
      <span>از صبوری شما سپاسگذاریم.</span>
    </div>

      </div>

      <img class="bg" src="../assets/img/half-circle.png" alt="">

    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingView"
}
</script>

<style scoped lang="scss">
.form-box{
  min-width: 100%;
}
.loading-images{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 140px;
  position: relative;
  margin: 0 auto;
  height: 240px;
  img{
    width: auto;
    position: absolute;
    top: 0;
    z-index: 3;
    opacity: 0;

    &.loading1{
      animation-name: anim1;
      animation-duration: 4s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }
    &.loading2{
      top: 64px;
      z-index: 2;
      animation-name: anim2;
      animation-duration: 4s;
      animation-delay: .5s;
      animation-iteration-count: infinite;
    }

    &.loading3{
      top: 127px;
      z-index: 1;
      animation-name: anim3;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    @keyframes anim3 {
      0% {top : 0 ; opacity: 0}
      50% {top: 127px; opacity: 1}
      100% {top: 160px; opacity: 0}
    }
    @keyframes anim2 {
      0% {top : 0 ; opacity: 0}
      50% {top: 64px; opacity: 1}
      100% {top: 127px; opacity: 0}
    }
    @keyframes anim1 {
      0% {top : 0 ; opacity: 0}
      50% {top: 2px; opacity: 1}
      100% {top:120px; opacity: 0}
    }

  }
}

.bottom{
  margin-top: 7rem;
  h4{
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
  }
}

</style>
