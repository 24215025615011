<template>
  <header class="header">

    <div class="container">
      <div class="row m-0 align-items-center">

        <div class="col-md-3 col-3">
          <div class="logo">
            <img v-if="!sizeMobile" src="../assets/logo-desc.png" alt="">
            <img v-if="sizeMobile" src="../assets/logo.png" alt="">
          </div>
        </div>

        <div class="col-md-6 menu-responsive" v-show="showMenu">
          <div class="menu-box">
            <a @click="scrollToSection('section3')">زیر سیستم ها</a>
            <a  @click="scrollToSection('section4')">سوالات متداول</a>
            <a @click="scrollToSection('section6')">درباره ما</a>
            <a @click="scrollToSection('section5')">تماس با ما</a>
            <div v-if="sizeMobile" class="left-sec-header">
              <button><i class="fa-light fa-user"></i>
              </button>
              <a href="">امروز شروع کنید!</a>
            </div>
          </div>
        </div>

        <div class="col-md-3 text-left d-flex m-0 col-9">
          <div class="left-sec-header mr-auto ">
            <router-link to="/auth/login">
              <button v-if="!sizeMobile"><i class="fa-light fa-user"></i>
              </button>
            </router-link>

            <a class="now-start" href="">امروز شروع کنید!</a>
            <span @click="showResMenu()" :class="{'show-menu':showMenu}" class="menu-res" v-if="sizeMobile"><i class=""></i></span>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>

export default {

  name: "Header-Component",
  data() {
    return {
      sizeMobile: false,
      showMenu:false
    }
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // اسکرول نرم
      }
    },
    showResMenu(){
      this.showMenu = !this.showMenu
    },
    checkSize() {
      if (window.innerWidth < 576) {
        this.sizeMobile = true
      }else{
        this.showMenu = true
      }
    }
  },
  mounted() {
    this.checkSize()
  }
}
</script>

<style scoped lang="scss">

header {
  padding: 10px 0;

  .logo {
    img {
      width: 100%;
    }
  }

  .menu-box {
    width: max-content;
    margin: 0 auto;
    background: #fff;
    border-radius: 99px;
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;

    a {
      padding: 12px 20px;
      border-radius: 99px;
      font-size: 14px;
      display: inline-block;
      margin: 0 5px;
      color: #333;
      border: 1px solid #E9E9E9;
      cursor: pointer;

    }
  }

  .left-sec-header {
    margin-right: auto;
    display: flex;
    align-items: center;

    button {
      padding: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 53px;
      height: 53px;
      margin-left: 10px;
      border-radius: 50%;
      background: #DBE0EB;

      i {
        color: #1F2937;
        font-size: 16px;
      }
    }

    a.now-start {
      background: #1F2937;
      padding: 16px 24px;
      display: inline-block;
      border-radius: 99px;
      color: #fff;
    }
  }
}


@media (max-width: 576px) {
  .menu-responsive {
    position: absolute;
    top: 104px;
    width: auto;
    left: 0;
    right: 0;

    .menu-box {
      border-radius: 40px;
      flex-wrap: wrap;

      a {
        width: 100%;
        border: none;
        font-weight: 600;
        font-size: 16px;
      }
      .left-sec-header {
        width: 100%;
        justify-content: left;
        a {
          width: auto;
        }
      }
    }

  }
  header {
    .logo {
      width: 100%;

      img {
        max-width: 100%;
        width: auto;

      }
    }



    .menu-res {
      position: relative;
      height: 20px;
      width: 40px;
      margin-right: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background: #000;
        transition: all ease .4s;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #000;
        transition: all ease .4s;
      }

      &.show-menu {
        &:before {
          content: '';
          transform: rotate(50deg);
          top: 7px;
        }

        &:after {
          content: '';
          transform: rotate(-50deg);
          bottom: 10px;
        }

      }


    }

    .left-sec-header {
      a {
        padding: 11px 19px;
      }
    }
  }



}

</style>
