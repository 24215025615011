<template>
  <div class="p-4">
    <div class="login-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <h2 class="w-100">انتخاب گذرواژه</h2>
        </div>

        <form @submit.prevent="submit()">
          <div class="input" :class="errorHandle ? 'error' : 'success'">
            <label for="">گذرواژه</label>
            <input autocomplete="off" @input="checkPasswordStrength" v-model="password"
                   :type="showPass ? 'text':'password'" placeholder=""/>
            <i @click="showPass = !showPass" class="fa-light password-create"
               :class=" !showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
          </div>
          <div class="error-box text-left " :class="errorHandle ? 'error' : 'success'">{{ passwordStrength }}</div>
          <div class="input" :class="{'error':errorRePass}">
            <label for="">تکرار گذرواژه</label>
            <input :type="showPass2 ? 'text':'password'" @input="checkRepeat" v-model="passwordRe" autocomplete="off"
                   placeholder=""/>
            <i @click="showPass2 = !showPass2" class="fa-light password-create"
               :class=" !showPass2 ? 'fa-eye-slash' : 'fa-eye'"></i>
          </div>
          <span v-if="errorRePass" class="text-danger w-100 d-block text-left">تکرار نادرست است</span>


          <div class="btns pt-4">

            <v-btn type="submit" class="brn btn-primary">تکمیل ثبت نام</v-btn>
            <span>قبلا اکانت ساخته اید؟  <router-link to="/auth/login">ورود</router-link></span>

          </div>
        </form>

      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "PasswordView",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      textSnack: false,
      colorSnack: '',
      password: "",
      passwordRe: "",
      passwordStrength: '',
      errorHandle: true,
      showPass: true,
      showPass2: true,
      errorRePass: false,
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
  },
  methods: {
    checkRepeat() {
      setTimeout(() => {

        if (this.password !== this.passwordRe) {
          this.errorRePass = true

        }else{
          this.errorRePass = false
        }


      }, 1000)

    },
    checkPasswordStrength() {
      const strongPasswordPattern = /^(?=.*[A-Z])(?=.*\d).{8,}$/; // حداقل 8 کاراکتر و شامل عدد و حرف بزرگ

      if (strongPasswordPattern.test(this.password)) {
        this.passwordStrength = "گذرواژه قدرتمند است";
        this.errorHandle = false
      } else {
        this.passwordStrength = "گذرواژه ضعیف است";
        this.errorHandle = true
      }
    },
    submit(){
      if (this.password !== this.passwordRe) {
       return
      }
      this.loading = true
      let body ={
        company_name : store.getters.getInformation.company_name,
        company_personnel: store.getters.getInformation.company_personnel,
        password: this.password
      }
      let token = this.getCookie()
      let auth = 'Bearer ' + token
      this.headers.Authorization = auth
      axios.post(this.apiUrl + 'api/accounts', body,
          {headers: this.headers})
      .then(() =>{

      })
      .catch(() =>{})
    },
    getCookie() {
      return Cookies.get('token');
    },
  },
  mounted() {
    if(store.getters.getInformation.company_name === ''){
      this.$router.push('/auth/register')
    }
  }
}
</script>

<style scoped lang="scss">
.input {
  label {
    color: #333;
  }

  &.success {
    color: #0E9F6E;

    input {
      color: #0E9F6E;
      border-color: #0E9F6E;

      &:focus {
        border-color: #0E9F6E !important;
      }
    }
  }

  &.error {
    color: #C27803;

    input {
      color: #C27803;
      border-color: #C27803;

      &:focus {
        border-color: #C27803 !important;
      }
    }

  }

}

.error {
  color: #C27803;
}

.success {
  color: #0E9F6E;
}

</style>
